import { toast } from "react-toastify";
import { BASE_URL } from "../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import moment from "moment";
import { generateNumber } from "../../utils/utils";
import { updateSRFAnalytics } from "../../utils/analytics/srfAnalytics";

const user = [localStorage.getItem("id"), localStorage.getItem("userName")];
let designation = localStorage.getItem("designation");

async function fetchCertificatesData(certIds) {
  try {
    const idList = certIds.map((id) => `'${id}'`).join(","); // Add quotes around each ID if needed
    const query = {
      query: `SELECT * FROM certificates WHERE id IN (${idList})`,
    };
    const url = `${BASE_URL}dynamic`;
    const response = await axiosWithToken.post(url, query);
    return response.data || [];
  } catch (error) {
    console.error("Error fetching certificate data:", error);
    throw error;
  }
}

async function updateCertificateStatus(certId, data) {
  try {
    const url = `${BASE_URL}certificates/${certId}`;
    await axiosWithToken.patch(url, data);
  } catch (error) {
    console.error("Error updating certificate status:", error);
    throw error;
  }
}

async function updateLastULRNo(settingList, count) {
  try {
    const newLastULRNo = Number(settingList?.["LASTULRNODIGIT"]) + count;
    const query = {
      query: `UPDATE settings SET value = ${newLastULRNo} WHERE keyName = 'LASTULRNODIGIT';`,
    };
    await axiosWithToken.post(`${BASE_URL}dynamic`, query);
    return newLastULRNo;
  } catch (error) {
    console.error("Error updating LASTULRNODIGIT:", error);
  }
}

async function ServeDates() {
  try {
    const url = `${BASE_URL}dynamic`;
    const query = `SELECT now() as date`;
    const response = await axiosWithToken.post(url, { query });
    return new Date(response.data[0]?.date || null);
  } catch (error) {
    console.error(error);
    return 0;
  }
}

export const mulipleApproveCertificates = async (
  action,
  compliantCerts,
  settingList
) => {
  let dateTime = await ServeDates();
  const certificatesData = await fetchCertificatesData(compliantCerts);

  if (action === "approve") {
    let allocatedCount = 0;

    // Check if ManualURL is false and count certificates that need ULR updates
    if (settingList?.["ManualURL"] === "false") {
      allocatedCount = certificatesData.filter(
        (cert) => cert.allocatedULRNo === 0
      ).length;
    }

    let successfulPatches = 0; // To track how many patches succeeded
    let newLastULRNo = 0;
    const successfullyApprovedIds = []; // Array to accumulate successful IDs

    // Process each certificate
    for (let i = 0; i < certificatesData.length; i++) {
      const cert = certificatesData[i];
      let data = {
        status: 3, // Approve status
        approvedby: Number(user[0]),
        approvedDate: moment(dateTime).format("YYYY-MM-DD HH:mm:ss"),
        designation: designation,
      };

      // Generate ULR number for each certificate
      if (settingList?.["ManualURL"] === "false" && cert.allocatedULRNo === 0) {
        data.allocatedULRNo = 1;

        // Generate the ULR number based on the current index
        if (newLastULRNo === 0) {
          // Update newLastULRNo only once based on the current LASTULRNODIGIT
          newLastULRNo = Number(settingList?.["LASTULRNODIGIT"]);
        }
        data.ULRNo = generateNumber(
          settingList?.["ULR number"],
          newLastULRNo + successfulPatches // Increment for each successful patch
        );
      }

      // Update the certificate status
      try {
        await updateCertificateStatus(cert.id, data);
        successfulPatches++; // Increment only if the patch was successful
        successfullyApprovedIds.push(cert.id); 
      } catch (error) {
        console.error(`Failed to update certificate ${cert.id}:`, error);
      }

      updateSRFAnalytics([cert.id], 80);
    }

      // Show success message for all successfully approved certificates at once
      if (successfullyApprovedIds.length > 0) {
        toast.success(`Certificate is approved Successfully`);
      }

    if (allocatedCount > 0 && successfulPatches > 0) {
      await updateLastULRNo(settingList, successfulPatches);
    }

    // Optionally reload or update the UI
    setTimeout(window.location.reload(false), 500);
  } else {
    console.error("Unknown action:", action);
    return;
  }
};
